import useStyles from "../../utils/useStyles";
import {Button, Card, CardContent, CardHeader, CircularProgress, Container, CssBaseline} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import api from "../../services/api";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {CheckBox} from "@material-ui/icons";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CreateIcon from "@material-ui/icons/Create";

export default function ListUser(props){
    const classes = useStyles();
    const [user, setUser] = useState([])

    useEffect(()=>{
        api.get('/user').then(res=>{
            setUser(res.data.data)
        })
    },[props])

    const columns = [
        {
            name: "Nome",
            selector: row=>row.user_details.name,
            sortable: true,
            //width:'10%'
        },
        {
            name: "Email",
            selector: row=>row.user_details.email,
            sortable: true,
            //width:'60%'
        },
        {
            name: "Ações",
            cell: row => <>
                <Link to={"/user/edit/" + row.id}><Button title={"Editar"} style={{margin:5}} variant={"contained"} color={"primary"}><CreateIcon/></Button></Link>
            </>
        }
    ];

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Utilizadores"}
                        action={<Link to={"/user/new"}><Button variant={"contained"} color={"primary"}><PersonAddIcon/></Button></Link>}
                    />
                    <CardContent>
                        <DataTable
                            columns={columns}
                            data={user}
                            sortIcon={<ArrowDownwardIcon/>}
                            pagination
                            selectableRowsComponent={CheckBox}
                            noDataComponent={"Não foi encontrados utilizadores"}
                            progressComponent={<CircularProgress/>}
                            paginationComponentOptions={{
                                rowsPerPageText: 'Linhas por pagina:',
                                rangeSeparatorText: 'de',
                                noRowsPerPage: false,
                                selectAllRowsItem: false,
                                selectAllRowsItemText: 'Todas'
                            }}
                        />
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}