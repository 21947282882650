import axios from "axios";
import Swal from "sweetalert2";
import {companyIsAuthenticated, getCompanyToken, getSession, logout, setNewAuthToken} from "./auth";

//export const url ="http://192.168.5.35:8000/api/v1/";
//export const url =window.location.protocol+"//api."+window.location.hostname+"/public/api/v1/";


//export const url ="http://"+window.location.hostname+":8000/api/v1/";
let urlSet = ""

if(process.env.NODE_ENV==="development"){
    urlSet="http://127.0.0.1:8000/api/v1/";
}else{
    urlSet ="https://api.beta.dr0008.drosera.pt/public/api/v1/";
}
export const url = urlSet
//export const url = "https://api.beta.dr0008.drosera.pt/public/api/v1/"
const api = axios.create({
    baseURL: url
});

api.interceptors.request.use(async config => {
    const session = getSession();
    if(session.status===true){
        config.headers.Authorization = `Bearer  `+session.data.token;
        config.headers['x-company']=session.data.company
    }else if(companyIsAuthenticated()){
        config.headers['x-company']=getCompanyToken()
    }
    return config;
});

api.interceptors.response.use(async res => {
        if(res.status===200){
        if(res.data.auth!==false && res.data.auth!==undefined){
            setNewAuthToken(res.data.auth)
        }
    }
    // Important: response interceptors **must** return the response.
    return res;
}, async function (error) {
    if (error.response.status === 401) {
        Swal.fire({
            title: "A sua sessão expirou",
            type: "warning",
            showCancelButton: false,
            closeOnConfirm: false
        }).then(r => {
            window.location = '/'
            logout()
        });
    } else if (error.response.status === 500) {
        await Swal.fire(
            'ERRO!',
            'Ocurreu um erro no servidor por favor tente mais tarde',
            'error',
        )
        window.location.href = "/";
    } else if (error.response.status === 503) {
        await Swal.fire(
            'Aviso!',
            'Aplicação em manutenção',
            'error',
        )
    } else {
        return Promise.reject(error)
    }
})

export default api;
