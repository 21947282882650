import api from "./api";

export const prefix = "@picking-";

export const COMPANY_TOKEN_KEY =prefix+"Company_Token";
export const COMPANY_NAME_KEY =prefix+"Company_Key";
export const AUTH_KEY =prefix+"Auth";
export const EMPLOYEE_KEY=prefix+"Employee";
export const Name_KEY=prefix+"Name";

export const isAuthenticated = () => localStorage.getItem(AUTH_KEY) !== null && localStorage.getItem(COMPANY_TOKEN_KEY) !== null;

export const companyIsAuthenticated = () => localStorage.getItem(COMPANY_TOKEN_KEY) !== null

export const username = ()=>localStorage.getItem(Name_KEY);

export const companyName = ()=>localStorage.getItem(COMPANY_NAME_KEY);

export const setCompanyAuth = (token, name)=>{
    localStorage.setItem(COMPANY_TOKEN_KEY,token)
    localStorage.setItem(COMPANY_NAME_KEY,name)
    return true
}

export const getCompanyToken = () => {
    return localStorage.getItem(COMPANY_TOKEN_KEY)
}

export const login = (auth, name, employee,)=>{
    localStorage.setItem(AUTH_KEY,auth)
    localStorage.setItem(EMPLOYEE_KEY,employee)
    localStorage.setItem(Name_KEY,name)
    return true
}

export const removeCompanyToken = ()=>{
    localStorage.removeItem(COMPANY_TOKEN_KEY)
    localStorage.removeItem(COMPANY_NAME_KEY)
    return true
}

export const getSession = ()=>{
    if(isAuthenticated()){
        return {
            status: true,
            data: {
                token: localStorage.getItem(AUTH_KEY),
                company: localStorage.getItem(COMPANY_TOKEN_KEY),
            }
        }
    }else{
        return {
            status: false,
            data: {
                token: "",
                company:""
            }
        }
    }
}

export const logout = () => {
    localStorage.clear();
};

export const refreshToken = ()=>{
    if(isAuthenticated) {
        api.get('refresh').then(res => {
            if (res.data.access_token !== undefined) {
                localStorage.setItem(AUTH_KEY, res.data.access_token)
            }
        })
    }
}

export const setNewAuthToken = (token)=>{
    localStorage.setItem(AUTH_KEY, token)
}