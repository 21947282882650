import {
    Button,
    Card,
    CardContent,
    CardHeader, Checkbox,
    Container,
    CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    FormControlLabel,
    Grid, InputLabel, MenuItem, Select,
    TextField
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import React, {useEffect, useState} from "react";
import useStyles from "../../utils/useStyles";
import api from "../../services/api";
import Swal from "sweetalert2";
import {useHistory} from "react-router-dom";
import {DeleteForever} from "@material-ui/icons";

export default function EditUser(props){
    const classes = useStyles();
    const [user, setUser] = useState({
        name:"",email:"",disabled:""
    })
    const history = useHistory();

    const [open, setOpen] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordConf, setPasswordConf] = useState("")

    const handleChangeName = (e) => {
        setUser({
            ...user,
            name:e.target.value
        })
    }

    const handleChangeEmail= (e) => {
        setUser({
            ...user,
            email:e.target.value
        })
    }
    const handleChangeDisabled= (e,value) => {
        setUser({
            ...user,
            disabled:value
        })
    }

    useEffect(()=>{
        api.get('user/'+props.match.params.id).then(res=>{
            console.log(Boolean(res.data.data.disabled))
            if(res.data.code===0){
                setUser({
                    ...res.data.data,
                    disabled:Boolean(res.data.data.disabled)
                })
            }
        })
    },[props.match.params.id])

    function save() {
        api.put('user/'+props.match.params.id,user).then(res=>{
            // eslint-disable-next-line
            if(res.data.code==0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                }).then(()=>{
                    history.push('/user')
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })
    }

    function changePassword(){
        api.put('user/password/'+props.match.params.id,{
            password,passwordConf
        }).then(res=>{
            // eslint-disable-next-line
            if(res.data.code==0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                }).then(()=>{
                    history.push('/user')
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })
    }

    function deleteUser(){
        Swal.fire({
            title: 'Deseja apagar este utilizador?',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: `Não`,
        }).then(r=>{
            if(r.isConfirmed){
                api.delete('/user/'+props.match.params.id).then(res=>{
                    // eslint-disable-next-line
                    if(res.data.code==0){
                        Swal.fire({
                            icon: "success",
                            text: res.data.message,
                            timerProgressBar:true,
                            timer:1500
                        }).then(()=>{
                            history.push('/user')
                        })
                    }else{
                        Swal.fire({
                            icon: "error",
                            text: res.data.message,
                            timerProgressBar:true,
                            timer:1500
                        })
                    }
                })
            }
        })
    }

    return (
        <Container component="main" maxWidth="xs" style={{minHeight:"60vh"}}>
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Editar utilizador"}
                        //subheader={scheduleData.name}
                        action={<>
                            <Button onClick={save} variant={"contained"} color={"primary"} title={"Guardar"}><SaveIcon/></Button>
                            <Button color={"secondary"} variant={"contained"} onClick={deleteUser}><DeleteForever/></Button>
                        </>}
                    >
                    </CardHeader>
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item md={6}>
                                <TextField onChange={handleChangeName} value={user.name} fullWidth={true} variant={"outlined"} name={"name"} label={"Nome"} />
                            </Grid>
                            <Grid item md={6}>
                                <TextField onChange={handleChangeEmail} value={user.email} fullWidth={true} variant={"outlined"} name={"email"} label={"Email"} type={"email"} />
                            </Grid>
                            {
                                user.disabled!==""?(
                                    <Grid item md={6}>
                                        <FormControlLabel style={{userSelect: 'none'}} checked={user.disable} control={<Checkbox defaultChecked={user.disabled}  onChange={handleChangeDisabled} value={user.disabled}  />} label="Desativar Utilizador" />
                                    </Grid>
                                ):null
                            }

                            <Grid item md={6}>
                                <Button onClick={()=>setOpen(true)} variant={"contained"} color={"primary"}>Alterar Password</Button>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </div>

            <Dialog maxWidth={'sm'} fullWidth open={open} onClose={()=> {
                setOpen(false)
            }}>
                <DialogTitle>Alterar Password</DialogTitle>
                <DialogContent >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="Abreviatura"
                                label="Password"
                                type="password"
                                fullWidth
                                variant="standard"
                                value={password}
                                onChange={e=>setPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="Descricao"
                                label="Confirmação de passwordq"
                                type="password"
                                fullWidth
                                variant="standard"
                                value={passwordConf}
                                onChange={e=>setPasswordConf(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>

                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)}>Cancelar</Button>
                    <Button onClick={()=>changePassword()}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}