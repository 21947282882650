export default function getTimeWork(periods, schedule){
    // eslint-disable-next-line
    if(schedule.flexible==0){
        let description = "";
        // eslint-disable-next-line
        periods.map(period=>{
            description+=period.timeStart+" - "+period.timeEnd+", ";
        })
        return description.substr(0, description.length - 2);
    }else{
        return "Horário flexível com "+periods[0].timeEnd+" horas de trabalho com "+periods[0].break+" horas de pausa";
    }
}