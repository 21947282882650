import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

export function generateExcel(collum, data, name="Relatorio"){
    let processData = renderData(collum,data);
    renderExcel(processData, name)
}

export function convertColumns(collum){
    let data = []
    // eslint-disable-next-line array-callback-return
    collum.map((value,idx)=>{
        if(value.name!=="Editar")
            data.push({
                name:value.name,
                key:idx
            })
    })
    return data
}

export function renderData (collum, data){
    let send = [[...getNameCollum(collum)]]
    send.push(...runSelector(data,collum))
    return send
}

function getNameCollum(collum) {
    let data = []
    // eslint-disable-next-line array-callback-return
    collum.map((value,idx)=>{
        data.push(
            value.name,
        )
    })
    return data
}

function runSelector(data=[], collum=[]) {
    let sendData = []
    // eslint-disable-next-line array-callback-return
    data.map((row)=>{
        let nextData = []
        // eslint-disable-next-line array-callback-return
        collum.map((col)=>{
            if(typeof col.selector==="function")
                if(col.selector(row)!=="[object Object]")
                    nextData.push(col.selector(row))
                else
                    nextData.push("")
            else
                nextData.push("")
        })
        sendData.push(nextData)
    })
    return sendData
}

export function renderExcel(data, name="Relatorio"){
    let wb = XLSX.utils.book_new();
    wb.Props = {
        Title: name,
        Subject: name,
        Author: "Capensis LDA",
        CreatedDate: new Date()
    };
    wb.SheetNames.push(name);
    wb.Sheets[name] = XLSX.utils.aoa_to_sheet(data);
    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), name+'.xlsx');
    /*var worksheet = XLSX.utils.aoa_to_sheet(data);
    var new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Incoerencias")*/
}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
}