import {
    Button,
    Card,
    CardContent, CardHeader,
    CircularProgress,
    Container,
    CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, TextField,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import {CheckBox,  GroupAdd} from "@material-ui/icons";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, {useEffect, useState} from "react";
import api from "../../services/api";
import CreateIcon from '@material-ui/icons/Create';
import Swal from "sweetalert2";
import useStyles from "../../utils/useStyles";

export default function DepartmentList(props) {
    const classes = useStyles();
    const [department, setDepartment] = useState([]);
    const [open, setOpen] = useState(false);
    const [departmentName,setDepartmentName] = useState("");
    const [edit,setEdit] = useState(false)
    const [id,setId] = useState(false)

    const columns = [
        {
            name: "Nome",
            selector: row=>row['name'],
            sortable: true,
            //width:'10%'
        },
        {
            name: "Ações",
            cell: (row, idx) => <>
                <Button title={"Editar"} style={{margin:5}} variant={"contained"} color={"primary"} onClick={()=>editDepartment(idx)}><CreateIcon/></Button>
            </>
        }
    ];

    const save = async () =>{
        let res
        if(edit===false) {
            res = await api.post('/department', {
                name: departmentName
            })
        }else{
            res = await api.put('department/'+id,{
                name: departmentName
            })
        }
        if(res.data.code===0){
            setOpen(false)
            await Swal.fire({
                icon: 'success',
                title: res.data.message,
                timer: 1000,
                timerProgressBar:true,
            })
            setDepartmentName("")
            setId("")
        }else{
            setOpen(false)
            await Swal.fire({
                icon: 'error',
                title: res.data.message,
                timer: 1000,
                timerProgressBar:true,
            })
            setOpen(true)
        }

    }

    const editDepartment = (idx)=>{
        setDepartmentName(department[idx].name)
        setId(department[idx].id)
        setEdit(true)
        setOpen(true)
    }


    useEffect(()=>{
        api.get('department').then(res=>{
            setDepartment(res.data.data)
        })
    },[open])


    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Departamentos"}
                        action={<Button onClick={()=>setOpen(true)} variant={"contained"} color={"primary"}><GroupAdd/></Button>}
                    />
                    <CardContent>
                        <Grid container spacing={1}>
                            <DataTable
                                columns={columns}
                                data={department}
                                sortIcon={<ArrowDownwardIcon/>}
                                pagination
                                selectableRowsComponent={CheckBox}
                                noDataComponent={"Não foi encontrados departamentos"}
                                progressComponent={<CircularProgress/>}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Linhas por pagina:',
                                    rangeSeparatorText: 'de',
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: 'Todas'
                                }}
                            />
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog maxWidth={'sm'} fullWidth open={open} onClose={()=> {
                setOpen(false)
                setEdit(false)
            }}>
                <DialogTitle>Departamento</DialogTitle>
                <DialogContent >
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Departamento"
                        type="text"
                        fullWidth
                        onKeyPress={e=>{
                            if(e.key === 'Enter') {
                                save()
                            }
                        }}
                        variant="standard"
                        value={departmentName}
                        onChange={e=>setDepartmentName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)}>Cancelar</Button>
                    <Button onClick={()=>save()}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}