import weekDays from "./weekDays";

export default function getDaysWorkOption(schedule){
    // eslint-disable-next-line
    if(schedule.monday==1 && schedule.tuesday==1 && schedule.wednesday==1 && schedule.thursday==1 && schedule.friday==1 && schedule.saturday==0 && schedule.sunday==0){
        return [weekDays[8]]
        // eslint-disable-next-line
    }else if(schedule.monday==1 && schedule.tuesday==1 && schedule.wednesday==1 && schedule.thursday==1 && schedule.friday==1 && schedule.saturday==1 && schedule.sunday==1){
        return [weekDays[7]]
    }else{
        let days=[]
        // eslint-disable-next-line
        if(schedule.monday==1)
            days.push(weekDays[0])
        // eslint-disable-next-line
        if(schedule.tuesday==1)
            days.push(weekDays[1])
        // eslint-disable-next-line
        if(schedule.wednesday==1)
            days.push(weekDays[2])
        // eslint-disable-next-line
        if(schedule.thursday==1)
            days.push(weekDays[3])
        // eslint-disable-next-line
        if(schedule.friday==1)
            days.push(weekDays[4])
        // eslint-disable-next-line
        if(schedule.saturday==1)
            days.push(weekDays[5])
        // eslint-disable-next-line
        if(schedule.sunday==1)
            days.push(weekDays[6])
        return  days
    }
}