import {Button, ButtonGroup} from "@material-ui/core";
import {TableChart, TextFields} from "@material-ui/icons";
import {generateExcel} from "../utils/excelUtils";
import {generatePDF} from "../utils/pdfUtils";

export default function ExportButtons({data, columns, name}){
    return (
        <ButtonGroup color="primary">
            <Button onClick={()=>generateExcel(columns,data, name)} startIcon={<TableChart/>}>Excel</Button>
            <Button onClick={()=>generatePDF(columns,data,name)} startIcon={<TextFields/>}>PDF</Button>
        </ButtonGroup>
    )
}