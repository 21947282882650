import {companyIsAuthenticated} from "./auth";
import React from "react";
import {  Route,  Redirect } from "react-router-dom";

const PickingRoutes =  ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            companyIsAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }}  from={'/'}/>
            )
        }
    />
);
export default  PickingRoutes;
