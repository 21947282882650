import React, {useEffect, useState} from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import {
    Button,
    Card,
    CardContent,
    CardHeader, Checkbox,
    Container,
    CssBaseline, FormControlLabel,
    Grid, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
} from "@material-ui/core";
import {TextField} from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {Box} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import  ColorPicker  from 'material-ui-color-picker';
import useStyles from "../../utils/useStyles";
import {Add, Delete} from "@material-ui/icons";
import Select from "react-select";
import weekDays from "../../utils/weekDays";
import getDaysWorkOption from "../../utils/getDaysWorkOption";



export default function ScheduleEdit (props){
    const classes = useStyles();

    const [scheduleData, setScheduleData] = useState({
        name:"",
        tolerance:0,
        color:"",
        tolerancePaid:true,
        employee_schedule:[],
        periods:[],
        disable:false
    });
    const [panel, setPanel] = useState('1')
    const [employee, setEmployee] = useState([])

    function guardar() {
        api.put('/schedule/' + props.match.params.id, {
            name:scheduleData.name,
            tolerance:scheduleData.tolerance,
            tolerancePaid:scheduleData.tolerancePaid,
            color:scheduleData.color,
            disable:scheduleData.disable
        }).then(res => {
            console.log(scheduleData.disable)
            if (res.data.code === 0 && (scheduleData.disable===false || scheduleData.disable===undefined)) {
                console.log(scheduleData.employee_schedule)
                for(let row of scheduleData.employee_schedule){
                    console.log(row)
                    if(row.id!==undefined){
                        api.put('schedule/employee/'+row.id,{
                            employeeID:row.employeeID,

                            scheduleID:props.match.params.id,
                            // eslint-disable-next-line
                            monday:row.monday==1,
                            // eslint-disable-next-line
                            tuesday:row.tuesday==1,
                            // eslint-disable-next-line
                            wednesday:row.wednesday==1,
                            // eslint-disable-next-line
                            thursday:row.thursday==1,
                            // eslint-disable-next-line
                            friday:row.friday==1,
                            // eslint-disable-next-line
                            saturday:row.saturday==1,
                            // eslint-disable-next-line
                            sunday:row.sunday==1
                        }).then(res=>{
                            if(res.data.code!==0){
                                Swal.fire({
                                    icon:"error",
                                    text:res.data.message,
                                    timerProgressBar:true,
                                    timer:1500
                                })
                            }
                        })
                    }else{
                        api.post('schedule/employee/',{
                            employeeID:row.employee.id,

                            scheduleID:props.match.params.id,
                            // eslint-disable-next-line
                            monday:row.monday==1,
                            // eslint-disable-next-line
                            tuesday:row.tuesday==1,
                            // eslint-disable-next-line
                            wednesday:row.wednesday==1,
                            // eslint-disable-next-line
                            thursday:row.thursday==1,
                            // eslint-disable-next-line
                            friday:row.friday==1,
                            // eslint-disable-next-line
                            saturday:row.saturday==1,
                            // eslint-disable-next-line
                            sunday:row.sunday==1
                        }).then(res=>{
                            if(res.data.code!==0){
                                Swal.fire({
                                    icon:"error",
                                    text:res.data.message,
                                    timerProgressBar:true,
                                    timer:1500
                                })
                            }
                        })
                    }
                }


                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            } else {
                Swal.fire({
                    icon: "error",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })
    }

    useEffect(()=>{
        api.get('/schedule/'+props.match.params.id).then(res=>{
            if(res.data.code===0){
                setScheduleData({
                    ...res.data.data,
                    tolerancePaid: Boolean(res.data.data.tolerancePaid)
                })
            }else{
                Swal.fire({
                    icon:"error",
                    text:res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })

        api.get('employee').then(res=>{
            if(res.data.code===0){
                setEmployee(res.data.data)
            }else{
                Swal.fire({
                    icon:"error",
                    text:res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })
    }, [props.match.params.id])

    const handleChangePanel = (event,newValue)=>{
        setPanel(newValue)
    }

    const handleChangeName = (e) =>{
        setScheduleData({
            ...scheduleData,
            name:e.target.value
        })
    }

    const handleChangeTolerance = (e)=>{
        setScheduleData({
            ...scheduleData,
            tolerance:e.target.value
        })
    }

    const handleChangePaid = (e)=>{
        setScheduleData({
            ...scheduleData,
            tolerancePaid:e.target.checked
        })
    }


    const handleChangeDisable = (e)=>{
        setScheduleData({
            ...scheduleData,
            disable:e.target.checked
        })
    }

    const handleChangeColor = (e)=>{
        setScheduleData({
            ...scheduleData,
            color:e
        })
    }

    const handleAddSchedule=()=>{
        let tmp = scheduleData.employee_schedule
        tmp.push({
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
            employeeID:0
        })
        setScheduleData({
            ...scheduleData,
            employee_schedule: tmp
        });
    }

    const handleSetEmployee = (value, idx) =>{
        let tmpData = scheduleData.employee_schedule;
        let schedule = tmpData[idx];
        schedule.employeeID=value.id;
        schedule.employee=value
        tmpData[idx]=schedule
        setScheduleData({
            ...scheduleData,
            employee_schedule: tmpData
        })

    }

    const handleScheduleDaysChange=(value,idx)=>{
        const days={
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
        }
        for(let row of value){
            if(row.value==="all-days"){
                days.monday=1;
                days.tuesday=1;
                days.wednesday=1;
                days.thursday=1;
                days.friday=1;
                days.saturday=1;
                days.sunday=1;
            }
            if(row.value==="weekdays"){
                days.monday=1;
                days.tuesday=1;
                days.wednesday=1;
                days.thursday=1;
                days.friday=1;
            }
            if(row.value==="monday"){
                days.monday=1;
            }
            if(row.value==="tuesday"){
                days.tuesday=1;
            }
            if(row.value==="wednesday"){
                days.wednesday=1;
            }
            if(row.value==="thursday"){
                days.thursday=1;
            }
            if(row.value==="friday"){
                days.friday=1;
            }
            if(row.value==="saturday"){
                days.saturday=1;
            }
            if(row.value==="sunday"){
                days.sunday=1;
            }
        }

        let tmpData = scheduleData.employee_schedule;
        days.schedule=tmpData[idx].schedule
        days.id=tmpData[idx].id
        days.employeeID=tmpData[idx].employeeID
        days.employee=tmpData[idx].employee
        console.log(tmpData[idx])
        tmpData[idx]=days;
        //tmpData[idx]=tmp.employeeID
        console.log(tmpData[idx])
        setScheduleData({...scheduleData,periods: tmpData})
    }

    const handleRemoveSchedule=(idx)=>{
        let tmp = scheduleData.employee_schedule
        if(tmp[idx].id!==undefined)
            api.delete('schedule/employee/'+tmp[idx].id).then(res=>{
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            })

        delete tmp[idx];
        tmp=tmp.filter(function (el) {
            return el != null;
        });
        setScheduleData({
            ...scheduleData,
            employee_schedule: tmp
        });
    }

    return (
        <Container component="main" maxWidth="xs" style={{minHeight:"60vh"}}>
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Horário"}
                        subheader={scheduleData.name}
                        action={<Button onClick={guardar} variant={"contained"} color={"primary"} title={"Guardar"}><SaveIcon/></Button>}
                    >
                    </CardHeader>
                    <CardContent>
                        <TabContext value={panel}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChangePanel}>
                                    <Tab label="Horário" value="1" />
                                    <Tab label="Períodos de trabalho" value="2" />
                                    <Tab label="Funcionários" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" style={{height:'60vh'}}>
                                <Grid container spacing={1}>
                                    <Grid item md={3}>
                                        <TextField onChange={handleChangeName} value={scheduleData.name}  variant={"outlined"} name={"name"} label={"Nome"} />
                                    </Grid>
                                    {scheduleData.flexible==0?(
                                        <>
                                            <Grid item md={3}>
                                                <TextField onChange={handleChangeTolerance} value={scheduleData.tolerance}  variant={"outlined"} name={"code"} label={"Tolerância"} type={"number"} />
                                            </Grid>
                                            <Grid item md={3}>
                                                <FormControlLabel style={{userSelect: 'none'}} checked={scheduleData.tolerancePaid} control={<Checkbox onChange={handleChangePaid} checked={scheduleData.tolerancePaid} />} label="Tolerância Remunerada" />
                                            </Grid>
                                        </>
                                    ):null}
                                    <Grid item md={3}>
                                        <ColorPicker value={scheduleData.color} label={"Cor"} variant={"outlined"} inputProps={{value:scheduleData.color, }} onChange={handleChangeColor}/>
                                    </Grid>

                                    <Grid item md={3}>
                                        <FormControlLabel style={{userSelect: 'none'}} checked={scheduleData.disable} control={<Checkbox onChange={handleChangeDisable} checked={scheduleData.disable} />} label="Desativar horário" />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="2">
                                <TableContainer component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            {scheduleData.flexible==0?(
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell><b>Entrada (FIXO?)</b></TableCell>
                                                    <TableCell><b>Saída (FIXO?)	</b></TableCell>
                                                    <TableCell><b>Total</b></TableCell>
                                                    <TableCell><b>Ações</b></TableCell>
                                                </TableRow>
                                                    ):(
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell><b>Tempo de trabalho</b></TableCell>
                                                    <TableCell><b>Tempo de pausa</b></TableCell>
                                                </TableRow>
                                            )}
                                        </TableHead>
                                        <TableBody>
                                            {scheduleData.periods.map((period,idx)=>(
                                                <TableRow key={idx}>
                                                    <TableCell>{idx+1}</TableCell>
                                                    <TableCell>
                                                        <TextField disabled variant={"outlined"} value={period.timeEnd} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField disabled variant={"outlined"} value={period.break} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel value="3">
                                {scheduleData.employee_schedule.length!==0?(
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell><b>Funcionário</b></TableCell>
                                                    <TableCell><b>Dias</b></TableCell>
                                                    <TableCell><b>Ações</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {scheduleData.employee_schedule.map((schedule,idx)=>(
                                                    <TableRow key={idx}>
                                                        <TableCell>{idx+1}</TableCell>
                                                        <TableCell>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                options={employee}
                                                                defaultValue={schedule.employee}
                                                                getOptionLabel ={(option)=>option.name}
                                                                getOptionValue ={(option)=>option.id}
                                                                onChange={value=>handleSetEmployee(value, idx)}
                                                                placeholder={"Funcionários"}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                placeholder={"Dias de trabalho"}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                options={weekDays}
                                                                defaultValue={getDaysWorkOption(schedule)}
                                                                onChange={value=>handleScheduleDaysChange(value,idx)}
                                                                isMulti
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <center>
                                                                        <Button variant={"outlined"} onClick={handleAddSchedule} color={"primary"}><Add/></Button>
                                                                    </center>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <center>
                                                                        <Button variant={"outlined"} disabled={scheduleData.employee_schedule.length===1}  onClick={()=>handleRemoveSchedule(idx)} color={"secondary"}><Delete/></Button>
                                                                    </center>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ):(
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <center>
                                                <Button variant={"outlined"} onClick={handleAddSchedule} color={"primary"}><Add/></Button>
                                            </center>
                                        </Grid>
                                    </Grid>
                                )}
                            </TabPanel>
                        </TabContext>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}