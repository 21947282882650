import {
    Button,
    Card,
    CardContent, CardHeader,
    CircularProgress,
    Container,
    CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText,
    Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import {CheckBox, Delete} from "@material-ui/icons";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, {useEffect, useState} from "react";
import api from "../../services/api";
import useStyles from "../../utils/useStyles";
import moment from "moment";
import MenuIcon from '@material-ui/icons/Menu';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import "moment/locale/pt";
import Swal from "sweetalert2";
import capitalize from "../../utils/capitalize";
import MomentUtils from '@date-io/moment';
import ExportButtons from "../../components/ExportButtons";
import {useRecoilState} from "recoil";
import {backdrop} from "../../atom";


export default function PickingList() {
    const classes = useStyles();
    const [inconsistencies, setInconsistencies] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [date, setDate] = useState(moment());
    const open = Boolean(anchorEl);
    const [pointSelected, setPointSelected] = useState({
        id:0,
        employee:{
            name:""
        },
        timeEntry:"",
        picking:{
            type:0
        }
    })
    const [openNewPoint, setOpenNewPoint] = useState(false);
    const [openEditPoint, setOpenEditPoint] = useState(false);
    const [type, setType] = useState(0);
    const [hour, setHour] = useState(moment());
    const [, setLoader] = useRecoilState(backdrop);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns = [
        {
            name: "Funcionário",
            // eslint-disable-next-line
            selector: row=>row.employee.name,
            sortable: true,
            width:'20%',
        },
        {
            name: "Picagem",
            selector: (row) => <p title={capitalize(moment(row.timeEntry).locale("Pt").fromNow())}>{moment(row.timeEntry).format('DD-MM-YYYY HH:mm:ss')}</p>,
            width:'12%',
            sortable: true,
        },
        {
            name: "Horário",
            selector: (row, idx) => row.schedule.name,
            width:'22%',
            sortable: false,
        },
        {
            name: "Período de trabalho",
            // eslint-disable-next-line
            selector:row=>row.periods!==null?row.schedule.flexible==0?(row.periods.timeStart+" - "+row.periods.timeEnd):row.periods.timeEnd+" Horas (Flexível)":"-",
            width:'15%',
            sortable: true,
        },{
            name: "Tipo de picagem",
            // eslint-disable-next-line
            selector: row=>row.type==0?"Entrada":(row.type==1?"Saida":"Não detetado"),
            width:'15%',
            sortable: true,
        },
        {
            name: "Ações",
            cell: (row, idx) => <>
                <Button onClick={(e)=>{
                    openMenuPopper(e)
                    setPointSelected(row)
                }
                } title={"Ações rápidas"} style={{margin:5}} variant={"contained"} color={"primary"}><MenuIcon/></Button>
            </>
        }
    ];

    useEffect(()=>{
        if(date!==null) {
            getList()
        }
        // eslint-disable-next-line
    },[date])

    function getList(){
        setLoader(true)
        api.post('picking/all', {
            month: date.format('MM-YYYY')
        }).then(res => {
            setInconsistencies(res.data.data)
            setLoader(false)
        })
    }

    function openMenuPopper(e){
        setAnchorEl(e.currentTarget);

    }

    function saveNew(){
        api.post('picking/new',{
            id:pointSelected.id,
            hour:hour.format('HH:mm')
        }).then(res=>{
            setOpenNewPoint(false)
            if(res.data.code===0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                }).then(()=> {
                    getList()
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                }).then(()=>{
                    setOpenNewPoint(true)
                })
            }
        })
    }

    function remove(){
        api.delete('picking/inconsistencies/correction/'+pointSelected.id).then(res=>{
            setOpenEditPoint(false)
            if(res.data.code===0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                }).then(()=> {
                    getList()
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message
                }).then(()=>{
                    setOpenNewPoint(true)

                })
            }
        })
    }

    function saveEdit(){
        api.post('picking/edit',{
            id:pointSelected.id,
            hour:hour.format('HH:mm'),
            type:type,
        }).then(res=>{
            setOpenEditPoint(false)
            if(res.data.code===0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timer:3000
                }).then(()=> {
                    getList()
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message
                }).then(()=>{
                    setOpenNewPoint(true)

                })
            }
        })
    }

    return(
        <Container component="main" maxWidth="xs">
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}  locale={"pt"}>
                <CssBaseline />
                <div className={classes.paper}>
                    <Card className={classes.root}>
                        <CardHeader
                            title={"Picagens"}
                            action={
                                <>
                                    <DatePicker
                                        variant={"outlined"}
                                        ampm={false}
                                        views={['year', 'month']}
                                        inputFormat={"MM/YYYY"}
                                        label="Selecionar mês"
                                        maxDate={moment()}
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField placeholder={""} {...params} helperText={null} />}
                                    />
                                </>}
                        />
                        <CardContent>
                            <Grid container spacing={1}>
                                <DataTable
                                    columns={columns}
                                    data={inconsistencies}
                                    sortIcon={<ArrowDownwardIcon/>}
                                    pagination
                                    selectableRowsComponent={CheckBox}
                                    noDataComponent={"Não foram encontradas incoerências"}
                                    progressComponent={<CircularProgress/>}
                                    paginationComponentOptions={{
                                        rowsPerPageText: 'Linhas por pagina:',
                                        rangeSeparatorText: 'de',
                                        noRowsPerPage: false,
                                        selectAllRowsItem: false,
                                        selectAllRowsItemText: 'Todas'
                                    }}
                                    subHeader
                                    subHeaderComponent={<ExportButtons data={inconsistencies} columns={columns} name={"Picagens de "+date.format("MMMM")}/>}
                                />
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={()=>{
                        handleClose()
                        setType(pointSelected.type)
                        setHour(moment(pointSelected.timeEntry))
                        setOpenEditPoint(true)
                    }

                    }>Alterar Ponto</MenuItem>
                    <MenuItem onClick={()=>{
                        handleClose()
                        if(pointSelected!==null){
                            if(pointSelected.type!==null){
                                // eslint-disable-next-line
                                if(pointSelected.type==1) {
                                    setHour(moment(pointSelected.periods.timeStart, 'HH:mm'))
                                }else{
                                    setHour(moment(pointSelected.periods.timeEnd, 'HH:mm'))
                                }
                            }else{
                                setHour(moment(pointSelected.periods.timeStart, 'HH:mm'))
                            }
                        }
                        setOpenNewPoint(true)
                    }}>Novo Ponto</MenuItem>
                </Menu>

                <Dialog maxWidth={'sm'} fullWidth open={openNewPoint} onClose={()=>setOpenNewPoint(false)}>
                    <DialogTitle>Adicionar Ponto - {pointSelected.employee.name}</DialogTitle>
                    <DialogContent >
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <TimePicker label={"Hora de picagem"} ampm={false} value={hour} onChange={data=>setHour(data)}  renderInput={(params) => <TextField {...params} />}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label={"Dia de picagem"} disabled value={moment(pointSelected.timeEntry).format("DD-MM-YYYY")} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenNewPoint(false)}>Cancelar</Button>
                        <Button onClick={()=>saveNew()}>Guardar</Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth={'sm'} fullWidth open={openEditPoint} onClose={()=>setOpenEditPoint(false)}>
                    <DialogTitle>
                        Editar Ponto - {pointSelected.employee.name}
                        <IconButton
                            aria-label="close"
                            onClick={remove}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Delete style={{color:'red'}} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent >
                        {pointSelected!==null?(
                            <Grid container xs={12} spacing={5} style={{marginTop:10}}>
                                <Grid item xs={6}>
                                    <TimePicker label={"Hora de picagem"}  ampm={false} value={hour} onChange={data=>setHour(data)}  renderInput={(params) => <TextField {...params} />}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label={"Dia de picagem"}  disabled value={moment(pointSelected.timeEntry).format("DD-MM-YYYY")} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Tipo de picagem</InputLabel>
                                        <Select value={type} onChange={e=>setType(e.target.value)} fullWidth label="Tipo de picagem">
                                            <MenuItem fullWidth selected disabled>Escolher uma opção</MenuItem>
                                            <MenuItem value={0} fullWidth>Entrada</MenuItem>
                                            <MenuItem value={1} fullWidth>Saida</MenuItem>
                                        </Select>
                                        <FormHelperText>Entrada ou Saida</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ):null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenEditPoint(false)}>Cancelar</Button>
                        <Button onClick={()=>saveEdit()}>Guardar</Button>
                    </DialogActions>
                </Dialog>
            </MuiPickersUtilsProvider>
        </Container>
    )
}