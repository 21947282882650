import moment from "moment"
import momentDurationFormatSetup from "moment-duration-format"

export default function getDiffTime(timeStart, timeEnd){

    momentDurationFormatSetup(moment);

    timeStart = moment(timeStart,"HH:mm")
    timeEnd = moment(timeEnd,"HH:mm")

    let ms = moment.duration(timeEnd.diff(timeStart))

    return ms.format("HH:mm")
}