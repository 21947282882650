import React from 'react';
import { SnackbarProvider } from 'notistack';
import theme from "./theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider}  from "@material-ui/core/styles";
import Routes from "./Routes";
import {RecoilRoot} from "recoil"

function App() {
  return (
      <SnackbarProvider maxSnack={5}>
          <ThemeProvider theme={theme}>
              <RecoilRoot>
                  <CssBaseline />
                  <Routes />
              </RecoilRoot>
          </ThemeProvider>
      </SnackbarProvider>
  );
}

export default App;
