import {Card, CardContent, Container, CssBaseline, Grid, Typography} from "@material-ui/core";
import useStyles from "../utils/useStyles";

export default function Dashboard(props) {
    const classes = useStyles();

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Dashboard
                        </Typography>
                        <Grid container spacing={1}>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}