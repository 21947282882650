import {
    Button,
    Card,
    CardContent, CardHeader,
    CircularProgress,
    Container,
    CssBaseline,
    Grid, Menu, MenuItem,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import {CheckBox, Add} from "@material-ui/icons";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, {useEffect, useState} from "react";
import api from "../../services/api";
import CreateIcon from '@material-ui/icons/Create';
import getTimeWork from "../../utils/getTimeWork";
import {Link} from "react-router-dom";
import useStyles from "../../utils/useStyles";


export default function ScheduleList() {
    const classes = useStyles();
    const [department, setDepartment] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns = [
        {
            name: "Nome",
            selector: row=>row['name'],
            sortable: true,
            //width:'10%'
        },
        {
            name: "Tempo de trabalho",
            cell: (row, idx) => getTimeWork(row.periods, row)
        },
        {
            name: "Tolerância",
            cell: (row, idx) => row.tolerance
        },
        {
            name: "Cor",
            cell: row=><div style={{display:'inline-block', height:24, width:24, backgroundColor:row.color}}/>
        },
        {
            name: "Ações",
            cell: (row, idx) => <>
                <Link to={"/schedule/" + row.id}><Button title={"Editar"} style={{margin:5}} variant={"contained"} color={"primary"}><CreateIcon/></Button></Link>
            </>
        }
    ];

    useEffect(()=>{
        api.get('schedule').then(res=>{
            setDepartment(res.data.data)
        })
    },[])


    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Horários"}
                        action={
                            <>
                                <Button onClick={handleClick} variant={"contained"} color={"primary"}>
                                    <Add/>
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <Link to={"/schedule/new/flexible"}><MenuItem>Horário flexível</MenuItem></Link>
                                    <Link to={"/schedule/new"}><MenuItem>Horário normal</MenuItem></Link>
                                </Menu>
                            </>}
                    />
                    <CardContent>
                        <Grid container spacing={1}>
                            <DataTable
                                columns={columns}
                                data={department}
                                sortIcon={<ArrowDownwardIcon/>}
                                pagination
                                selectableRowsComponent={CheckBox}
                                noDataComponent={"Não foram encontrados horários"}
                                progressComponent={<CircularProgress/>}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Linhas por pagina:',
                                    rangeSeparatorText: 'de',
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: 'Todas'
                                }}
                            />
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}