import React, {useEffect,  useState} from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    CssBaseline,
    Grid, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
} from "@material-ui/core";
import {TextField} from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {Box} from "@material-ui/core";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import useStyles from "../../utils/useStyles";
import weekDays from "../../utils/weekDays";
import getDaysWorkOption from "../../utils/getDaysWorkOption";

export default function EmployeeNew (props){
    const classes = useStyles();

    const [employeeData, setEmployeeData] = useState({
        name:"",
        code:"",
        schedule:[]
    });
    const [departments, setDepartments] = useState([]);
    const [panel, setPanel] = useState('1')
    const [departmentVal, setDepartmentVal] = useState([])
    const [schedules, setSchedules]=useState([]);
    const [employeeSchedules, setEmployeeSchedules] = useState([])


    const handleChangePanel = (event,newValue)=>{
        setPanel(newValue)
    }

    const handleChangeDepartment = (newValue) => {
        setDepartmentVal(newValue)
    };

    const handleChangeName = (e) =>{
        setEmployeeData({
            ...employeeData,
            name:e.target.value
        })
    }

    const handleChangeCode = (e)=>{
        setEmployeeData({
            ...employeeData,
            code:e.target.value
        })
    }

    const handleScheduleChange=(value,idx)=>{
        let tmpData = employeeSchedules;
        tmpData[idx].schedule=value
        setEmployeeSchedules(tmpData)
    }

    const handleRemoveSchedule=(idx)=>{
        let tmpData = employeeSchedules;
        if(tmpData[idx].id!==undefined)
            api.delete('schedule/employee/'+tmpData[idx].id)
        
        delete tmpData[idx];
        tmpData=tmpData.filter(function (el) {
            return el != null;
        });
        setEmployeeSchedules(tmpData)
    }

    const handleAddSchedule=()=>{
        setEmployeeSchedules([...employeeSchedules, {
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
            schedule: {
            }
        }]);
    }

    const handleScheduleDaysChange=(value,idx)=>{
        const days={
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
        }
        for(let row of value){
            if(row.value==="all-days"){
                days.monday=1;
                days.tuesday=1;
                days.wednesday=1;
                days.thursday=1;
                days.friday=1;
                days.saturday=1;
                days.sunday=1;
            }
            if(row.value==="weekdays"){
                days.monday=1;
                days.tuesday=1;
                days.wednesday=1;
                days.thursday=1;
                days.friday=1;
            }
            if(row.value==="monday"){
                days.monday=1;
            }
            if(row.value==="tuesday"){
                days.tuesday=1;
            }
            if(row.value==="wednesday"){
                days.wednesday=1;
            }
            if(row.value==="thursday"){
                days.thursday=1;
            }
            if(row.value==="friday"){
                days.friday=1;
            }
            if(row.value==="saturday"){
                days.saturday=1;
            }
            if(row.value==="sunday"){
                days.sunday=1;
            }
        }
        //console.log(employeeSchedules[idx])
        let tmpData = employeeSchedules;
        days.schedule=tmpData[idx].schedule
        days.id=tmpData[idx].id
        tmpData[idx]=days
        setEmployeeSchedules(tmpData)
    }

    function save(){
        api.post('/employee',{
            name:employeeData.name,
            code:employeeData.code,
            department:departmentVal.label
        }).then(res=>{
            if(res.data.code===0){
                Swal.fire({
                    icon:"success",
                    text:res.data.message
                })
                for(let row of employeeSchedules){
                    api.post('schedule/employee',{
                        employeeID:res.data.id,
                        scheduleID:row.schedule.id,
                        // eslint-disable-next-line
                        monday:row.monday==1,
                        // eslint-disable-next-line
                        tuesday:row.tuesday==1,
                        // eslint-disable-next-line
                        wednesday:row.wednesday==1,
                        // eslint-disable-next-line
                        thursday:row.thursday==1,
                        // eslint-disable-next-line
                        friday:row.friday==1,
                        // eslint-disable-next-line
                        saturday:row.saturday==1,
                        // eslint-disable-next-line
                        sunday:row.sunday==1
                    }).then(res=>{
                        if(res.data.code!==0){
                            Swal.fire({
                                icon:"error",
                                text:res.data.message,
                                timerProgressBar:true,
                                timer:1500
                            })
                        }
                    })
                }
            }else{
                Swal.fire({
                    icon:"error",
                    text:res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })

    }

    useEffect(()=>{
        api.get('/department').then(res=>{
            if(res.data.code===0){
                let tmp=[];
                for(let row of res.data.data){
                    tmp.push({
                        label:row.name,
                        value:row.name
                    })
                }
                setDepartments(tmp)
            }else{
                Swal.fire({
                    icon:"error",
                    text:res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })

        api.get('schedule').then(res=>{
            if(res.data.code===0){
                setSchedules(res.data.data)
            }else{
                Swal.fire({
                    icon:"error",
                    text:res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })
    }, [props.match.params.id])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Funcionário"}
                        action={<Button onClick={save} variant={"contained"} color={"primary"} title={"Guardar"}><SaveIcon/></Button>}
                    >
                    </CardHeader>
                    <CardContent>
                            <TabContext value={panel}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangePanel} aria-label="lab API tabs example">
                                        <Tab label="Ficha de Funcionário" value="1" />
                                        <Tab label="Departamento" value="2" />
                                        <Tab label="Horário" value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Grid container spacing={1}>
                                        <Grid item md={3}>
                                            <TextField onChange={handleChangeName} value={employeeData.name}  variant={"outlined"} name={"name"} label={"Nome"} />
                                        </Grid>
                                        <Grid item md={3}>
                                            <TextField onChange={handleChangeCode} value={employeeData.code}  variant={"outlined"} name={"code"} label={"Código de acesso"} type={"number"} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="2">
                                    <CreatableSelect
                                        onChange={handleChangeDepartment}
                                        formatCreateLabel={userInput => `Criar Departamento: "${userInput}"`}
                                        placeholder={"Departamento"}
                                        defaultValue={departmentVal}
                                        options={departments}
                                        isClearable={false}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </TabPanel>
                                <TabPanel value="3">
                                    <Button variant={"outlined"} onClick={handleAddSchedule} color={"primary"}><AddIcon/></Button>
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell><b>Hórario</b></TableCell>
                                                    <TableCell><b>Dias</b></TableCell>
                                                    <TableCell><b>Ações</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {employeeSchedules.map((schedule,idx)=>(
                                                    <TableRow key={idx}>
                                                        <TableCell>{idx+1}</TableCell>
                                                        <TableCell>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                options={schedules}
                                                                defaultValue={schedule.schedule}
                                                                getOptionLabel ={(option)=>option.name}
                                                                getOptionValue ={(option)=>option.id}
                                                                onChange={value=>handleScheduleChange(value, idx)}

                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                placeholder={"Dias de trabalho"}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                options={weekDays}
                                                                defaultValue={getDaysWorkOption(schedule)}
                                                                onChange={value=>handleScheduleDaysChange(value,idx)}
                                                                isMulti
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <center>
                                                                        <Button variant={"outlined"} onClick={handleAddSchedule} color={"primary"}><AddIcon/></Button>
                                                                    </center>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <center>
                                                                        <Button variant={"outlined"} onClick={()=>handleRemoveSchedule(idx)} color={"secondary"}><DeleteIcon/></Button>
                                                                    </center>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </TabContext>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}