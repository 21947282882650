import {Button, Card, CardContent, CardHeader, Container, CssBaseline, Grid, TextField} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import React, {useState} from "react";
import useStyles from "../../utils/useStyles";
import api from "../../services/api";
import Swal from "sweetalert2";
import {useHistory} from "react-router-dom";

export default function NewUser(){
    const classes = useStyles();
    const [user, setUser] = useState({
        name:"",email:"",password:"",confirmPassword:""
    })
    const history = useHistory();


    const handleChangeName = (e) => {
        setUser({
            ...user,
            name:e.target.value
        })
    }

    const handleChangeEmail= (e) => {
        setUser({
            ...user,
            email:e.target.value
        })
    }

    const handleChangePassword= (e) => {
        setUser({
            ...user,
            password:e.target.value
        })
    }

    const handleChangeConfirmPassword= (e) => {
        setUser({
            ...user,
            confirmPassword:e.target.value
        })
    }

    function save() {
        api.post('user',user).then(res=>{
            // eslint-disable-next-line
            if(res.data.code==0){
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                }).then(()=>{
                    history.push('/user')
                })
            }else{
                Swal.fire({
                    icon: "error",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })
    }

    return (
        <Container component="main" maxWidth="xs" style={{minHeight:"60vh"}}>
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Novo utilizador"}
                        //subheader={scheduleData.name}
                        action={<Button onClick={save} variant={"contained"} color={"primary"} title={"Guardar"}><SaveIcon/></Button>}
                    >
                    </CardHeader>
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item md={6}>
                                <TextField onChange={handleChangeName} value={user.name} fullWidth={true} variant={"outlined"} name={"name"} label={"Nome"} />
                            </Grid>
                            <Grid item md={6}>
                                <TextField onChange={handleChangeEmail} value={user.email} fullWidth={true} variant={"outlined"} name={"email"} label={"Email"} type={"email"} />
                            </Grid>
                            <Grid item md={6}>
                                <TextField onChange={handleChangePassword} value={user.password} fullWidth={true} variant={"outlined"} name={"password"} label={"Password"} type={"password"} />
                            </Grid>
                            <Grid item md={6}>
                                <TextField onChange={handleChangeConfirmPassword} value={user.confirmPassword} fullWidth={true} variant={"outlined"} name={"confirmPassword"} label={"Confirmação de password"} type={"password"} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}