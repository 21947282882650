const weekDays=[{
    label: "Segunda",
    value:"monday",
},{
    label: "Terça",
    value:"tuesday",
},{
    label: "Quarta",
    value:"wednesday",
},{
    label: "Quinta",
    value:"thursday",
},{
    label: "Sexta",
    value:"friday",
},{
    label: "Sábado",
    value:"saturday",
},{
    label: "Domingo",
    value:"sunday",
},{
    label: "Todos os dias",
    value:"all-days",
},{
    label: "Dias da semana",
    value:"weekdays",
},
];

export default weekDays