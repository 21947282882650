import {
    Button,
    Card,
    CardContent, CardHeader,
    CircularProgress,
    Container,
    CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    Grid, InputLabel, MenuItem, Select, TextField,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import {CheckBox, AddCircle} from "@material-ui/icons";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, {useEffect, useState} from "react";
import api from "../../services/api";
import CreateIcon from '@material-ui/icons/Create';
import useStyles from "../../utils/useStyles";
import Swal from "sweetalert2";


export default function JustifyList() {
    const classes = useStyles();
    const [justify, setJustify] = useState([]);
    const [open, setOpen] = useState(false);
    const [edit,setEdit] = useState(false);
    const [abbreviation,setAbbreviation] = useState("");
    const [description, setDescription] = useState("");
    const [paid,setPaid] = useState(0)
    const [id,setID] = useState(0)

    const handleClick = (event) => {
        setEdit(false)
        setOpen(true)
    };

    const columns = [
        {
            name: "Abreviatura",
            selector: row=>row['abbreviation'],
            sortable: true,
            //width:'10%'
        },
        {
            name: "Descrição",
            selector: row=>row['name'],
            sortable: true,
        },
        {
            name: "Remunerado",
            // eslint-disable-next-line
            cell: (row, idx) => row.paid==1?"Sim":"Não"
        },
        {
            name: "Ações",
            cell: (row, idx) => <>
                <Button onClick={()=>setJustifyEdit(row)} title={"Editar"} style={{margin:5}} variant={"contained"} color={"primary"}><CreateIcon/></Button>
            </>
        }
    ];

    useEffect(()=>{
        api.get('justify').then(res=>{
            setJustify(res.data.data)
        })
    },[open])

    const setJustifyEdit = (row) => {
        setAbbreviation(row.abbreviation)
        setDescription(row.name)
        setPaid(row.paid)
        setEdit(true)
        setOpen(true)
        setID(row.id)
    }

    const save = async () => {
        var res
        if(edit){
            res=await api.put('justify/'+id,{
                abbreviation,
                name:description,
                paid,
                disabled:0
            })
        }else{
            res=await api.post('justify',{
                abbreviation,
                name:description,
                paid,
                disabled:0
            })
        }
        if(res.data.code===0){
            setOpen(false)
            await Swal.fire({
                icon: 'success',
                text: res.data.message,
                timerProgressBar:true,
                timer:1500
            })
            setDescription("")
            setAbbreviation("")
            setID("")
        }else{
            setOpen(false)
            await Swal.fire({
                icon: 'error',
                text: res.data.message,
                timerProgressBar:true,
                timer:1500
            })
            setOpen(true)
        }
    }


    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Justificações"}
                        action={
                            <>
                                <Button onClick={handleClick} variant={"contained"} color={"primary"}>
                                    <AddCircle/>
                                </Button>
                            </>}
                    />
                    <CardContent>
                        <Grid container spacing={1}>
                            <DataTable
                                columns={columns}
                                data={justify}
                                sortIcon={<ArrowDownwardIcon/>}
                                pagination
                                selectableRowsComponent={CheckBox}
                                noDataComponent={"Não foram encontrados horários"}
                                progressComponent={<CircularProgress/>}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Linhas por pagina:',
                                    rangeSeparatorText: 'de',
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: 'Todas'
                                }}
                            />
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog maxWidth={'sm'} fullWidth open={open} onClose={()=> {
                setOpen(false)
                setEdit(false)
            }}>
                <DialogTitle>Justificação</DialogTitle>
                <DialogContent >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="Abreviatura"
                                label="Abreviatura"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={abbreviation}
                                onChange={e=>setAbbreviation(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="Descricao"
                                label="Descrição"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={description}
                                onChange={e=>setDescription(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Remunerado</InputLabel>
                                <Select value={paid} fullWidth={true} onChange={e=>setPaid(e.target.value)} label={"Remunerado"}>
                                    <MenuItem value={1} fullWidth={true}>Sim</MenuItem>
                                    <MenuItem value={0} fullWidth={true}>Não</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)}>Cancelar</Button>
                    <Button onClick={()=>save()}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}