import moment from "moment";

export default function getWeekDays(startDate, endDate) {
    var day = moment(startDate);
    var businessDays = 0;

    while (day.isSameOrBefore(endDate,'day')) {
        if (day.day()!=0 && day.day()!=6) businessDays++;
        day.add(1,'d');
    }
    return businessDays;
}