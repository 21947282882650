import jsPDF from 'jspdf'
import 'jspdf-autotable'

export function generatePDF(collum, data, name="Relatorio"){
    const doc = new jsPDF('l')
    doc.text(name, 10, 10);

    doc.autoTable({
        head: [getNameCollum(collum)],
        body: renderData(collum, data)
    })

    doc.save(name+'.pdf')
}

export function renderData (collum, data){
    //let send = [[...getNameCollum(collum)]]
    let send = []
    send.push(...runSelector(data,collum))
    console.log(send)
    return send
}

function getNameCollum(collum) {
    let data = []
    // eslint-disable-next-line array-callback-return
    collum.map((value,idx)=>{
        if(value.name!=="Editar")
        data.push(
            value.name,
        )
    })
    console.error(data)
    return data
}

function runSelector(data=[], collum=[]) {
    let sendData = []
    // eslint-disable-next-line array-callback-return
    data.map((row)=>{
        let nextData = []
        // eslint-disable-next-line array-callback-return
        collum.map((col)=>{
            if(typeof col.selector==="function")
                if(col.selector(row)!="[object Object]")
                    nextData.push(col.selector(row))
                else
                    nextData.push("")
            else
                nextData.push("")
        })
        sendData.push(nextData)
    })
    return sendData
}