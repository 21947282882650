import React, {useEffect, useState} from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    CssBaseline,
    Grid, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
} from "@material-ui/core";
import {TextField} from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {Box} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import  ColorPicker  from 'material-ui-color-picker';
import useStyles from "../../utils/useStyles";
import {Add, Delete} from "@material-ui/icons";
import Select from "react-select";
import weekDays from "../../utils/weekDays";
import getDaysWorkOption from "../../utils/getDaysWorkOption";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";


export default function ScheduleNewFlexible (){
    const classes = useStyles();

    const [scheduleData, setScheduleData] = useState({
        name:"",
        tolerance:0,
        color:"",
        tolerancePaid:true,
        employee_schedule:[{
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
            employeeID:0
        }],
        periods:[{
            timeStart:moment("00:00","HH:mm"),
            timeEnd:moment("00:00","HH:mm"),
            breakTime:moment("00:00","HH:mm"),
            timeStartFixed: "0",
            timeEndFixed: "0",
        }]
    });
    const [panel, setPanel] = useState('1')
    const [employee, setEmployee] = useState([])

    function save() {
        api.post('/schedule/flexible', {
            name:scheduleData.name,
            tolerance:scheduleData.tolerance,
            tolerancePaid:scheduleData.tolerancePaid,
            color:scheduleData.color,
            period:{
                timeStart:scheduleData.periods[0].timeStart.format("HH:mm"),
                timeEnd:scheduleData.periods[0].timeEnd.format("HH:mm"),
                breakTime:scheduleData.periods[0].breakTime.format("HH:mm"),
                timeStartFixed:scheduleData.periods[0].timeStartFixed,
                timeEndFixed:scheduleData.periods[0].timeEndFixed,
            }
        }).then(res => {
            if (res.data.code === 0) {
                for(let row of scheduleData.employee_schedule) {
                    if(row.employee!==undefined){
                        api.post('schedule/employee/', {
                            employeeID: row.employee.id,

                            scheduleID: res.data.data,
                            // eslint-disable-next-line
                            monday: row.monday == 1,
                            // eslint-disable-next-line
                            tuesday: row.tuesday == 1,
                            // eslint-disable-next-line
                            wednesday: row.wednesday == 1,
                            // eslint-disable-next-line
                            thursday: row.thursday == 1,
                            // eslint-disable-next-line
                            friday: row.friday == 1,
                            // eslint-disable-next-line
                            saturday: row.saturday == 1,
                            // eslint-disable-next-line
                            sunday: row.sunday == 1
                        }).then(res => {
                            if (res.data.code !== 0) {
                                Swal.fire({
                                    icon: "error",
                                    text: res.data.message,
                                    timerProgressBar:true,
                                    timer:1500
                                })
                            }
                        })
                    }
                }
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })

            } else {
                Swal.fire({
                    icon: "error",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })
    }

    useEffect(()=>{
        api.get('employee').then(res=>{
            if(res.data.code===0){
                setEmployee(res.data.data)
            }else{
                Swal.fire({
                    icon:"error",
                    text:res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            }
        })
    }, [])

    const handleChangePanel = (event,newValue)=>{
        setPanel(newValue)
    }

    const handleChangeName = (e) =>{
        setScheduleData({
            ...scheduleData,
            name:e.target.value
        })
    }

    const handleChangeColor = (e)=>{
        setScheduleData({
            ...scheduleData,
            color:e
        })
    }

    const handleAddSchedule=()=>{
        let tmp = scheduleData.employee_schedule
        tmp.push({
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
            employeeID:0
        })
        setScheduleData({
            ...scheduleData,
            employee_schedule: tmp
        });
    }

    const handleSetEmployee = (value, idx) =>{
        let tmpData = scheduleData.employee_schedule;
        let schedule = tmpData[idx];
        schedule.employeeID=value.id;
        schedule.employee=value
        tmpData[idx]=schedule


        setScheduleData({
            ...scheduleData,
            employee_schedule: tmpData,
        })

    }

    const handleChangeEndTime = (value, idx)=>{
        let tmpData = scheduleData.periods;
        tmpData[idx].timeEnd=value;
        setScheduleData({
            ...scheduleData,
            periods: tmpData
        })
    }

    const handleChangeBreakTime = (value, idx)=>{
        let tmpData = scheduleData.periods;
        tmpData[idx].breakTime=value;
        setScheduleData({
            ...scheduleData,
            periods: tmpData
        })
    }

    const handleScheduleDaysChange=(value,idx)=>{
        const days={
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
            sunday: 0,
        }
        for(let row of value){
            if(row.value==="all-days"){
                days.monday=1;
                days.tuesday=1;
                days.wednesday=1;
                days.thursday=1;
                days.friday=1;
                days.saturday=1;
                days.sunday=1;
            }
            if(row.value==="weekdays"){
                days.monday=1;
                days.tuesday=1;
                days.wednesday=1;
                days.thursday=1;
                days.friday=1;
            }
            if(row.value==="monday"){
                days.monday=1;
            }
            if(row.value==="tuesday"){
                days.tuesday=1;
            }
            if(row.value==="wednesday"){
                days.wednesday=1;
            }
            if(row.value==="thursday"){
                days.thursday=1;
            }
            if(row.value==="friday"){
                days.friday=1;
            }
            if(row.value==="saturday"){
                days.saturday=1;
            }
            if(row.value==="sunday"){
                days.sunday=1;
            }
        }

        let tmpData = scheduleData.employee_schedule;
        days.schedule=tmpData[idx].schedule
        days.id=tmpData[idx].id
        tmpData[idx]=days
        setScheduleData({...scheduleData,employee_schedule: tmpData})
    }

    const handleRemoveSchedule=(idx)=>{
        let tmp = scheduleData.employee_schedule
        if(tmp[idx].id!==undefined)
            api.delete('schedule/employee/'+tmp[idx].id).then(res=>{
                Swal.fire({
                    icon: "success",
                    text: res.data.message,
                    timerProgressBar:true,
                    timer:1500
                })
            })

        delete tmp[idx];
        tmp=tmp.filter(function (el) {
            return el != null;
        });
        setScheduleData({
            ...scheduleData,
            employee_schedule: tmp
        });
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={"ptLocale"}>
            <Container component="main" maxWidth="xs" style={{minHeight:"60vh"}}>
                <CssBaseline />
                <div className={classes.paper}>
                    <Card className={classes.root}>
                        <CardHeader
                            title={"Horário Flexível"}
                            subheader={scheduleData.name}
                            action={<Button onClick={save} variant={"contained"} color={"primary"} title={"Guardar"}><SaveIcon/></Button>}
                        >
                        </CardHeader>
                        <CardContent>
                            <TabContext value={panel}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangePanel}>
                                        <Tab label="Horário" value="1" />
                                        <Tab label="Períodos de trabalho" value="2" />
                                        <Tab label="Funcionários" value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" style={{height:'60vh'}}>
                                    <Grid container spacing={1}>
                                        <Grid item md={3}>
                                            <TextField onChange={handleChangeName} value={scheduleData.name}  variant={"outlined"} name={"name"} label={"Nome"} />
                                        </Grid>
                                        <Grid item md={3}>
                                            <ColorPicker value={scheduleData.color} label={"Cor"} variant={"outlined"} inputProps={{value:scheduleData.color, }} onChange={handleChangeColor}/>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="2">
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell><b>Tempo de trabalho	</b></TableCell>
                                                    <TableCell><b>Tempo de pausa	</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {scheduleData.periods.map((period,idx)=>(
                                                    <TableRow key={idx}>
                                                        <TableCell>{idx+1}</TableCell>
                                                        <TableCell>
                                                            <TimePicker
                                                                variant={"outlined"}
                                                                ampm={false}
                                                                value={period.timeEnd}
                                                                orientation="landscape"
                                                                onChange={e=>handleChangeEndTime(e, idx)}
                                                                renderInput={(params) => <TextField {...params} label={"Tempo de trabalho"} />}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TimePicker
                                                                variant={"outlined"}
                                                                ampm={false}
                                                                value={period.breakTime}
                                                                orientation="landscape"
                                                                onChange={e=>handleChangeBreakTime(e, idx)}
                                                                renderInput={(params) => <TextField {...params} label={"Tempo de pausa"} />}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel value="3">
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell><b>Funcionário</b></TableCell>
                                                    <TableCell><b>Dias</b></TableCell>
                                                    <TableCell><b>Ações</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {scheduleData.employee_schedule.length!==0?scheduleData.employee_schedule.map((schedule,idx)=>(
                                                    <TableRow key={idx}>
                                                        <TableCell>{idx+1}</TableCell>
                                                        <TableCell>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                options={employee}
                                                                defaultValue={schedule.employee}
                                                                getOptionLabel ={(option)=>option.name}
                                                                getOptionValue ={(option)=>option.id}
                                                                onChange={value=>handleSetEmployee(value, idx)}
                                                                placeholder={"Funcionários"}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Select
                                                                menuPortalTarget={document.body}
                                                                placeholder={"Dias de trabalho"}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                options={weekDays}
                                                                defaultValue={getDaysWorkOption(schedule)}
                                                                onChange={value=>handleScheduleDaysChange(value,idx)}
                                                                isMulti
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <center>
                                                                        <Button variant={"outlined"} onClick={handleAddSchedule} color={"primary"}><Add/></Button>
                                                                    </center>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <center>
                                                                        <Button variant={"outlined"} disabled={scheduleData.employee_schedule.length===1}  onClick={()=>handleRemoveSchedule(idx)} color={"secondary"}><Delete/></Button>
                                                                    </center>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                )):(
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <center>
                                                                <Button variant={"outlined"} onClick={handleAddSchedule} color={"primary"}><Add/></Button>
                                                            </center>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </TabContext>
                        </CardContent>
                    </Card>
                </div>
            </Container>
        </MuiPickersUtilsProvider>
    )
}