import React, {useEffect, useState} from 'react';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Container, Dialog, DialogContent, DialogTitle,
    Link, List, ListItem,
    Typography
} from "@material-ui/core";
import {Form} from "@unform/web";
import {TextField} from "unform-material-ui";
import {TextField as TextFieldOriginal} from "@material-ui/core"
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import api from "../services/api";
import {companyIsAuthenticated, isAuthenticated, login, setCompanyAuth} from "../services/auth";
import Swal from "sweetalert2";
import {Alert} from "@material-ui/lab";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://capensis.pt/">
                Capensis LDA
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Login() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({open:false, message:"", type:"info"});
    const [company, setCompany] = useState([]);
    const [companyDialog, setCompanyDialog] = useState(false);
    const [companyCodeDialog,setCompanyCodeDialog]=useState(false);
    const [companyCode, setCompanyCode] = useState("")

    useEffect(()=>{
        if(companyIsAuthenticated() && isAuthenticated()===false){

        }else if(isAuthenticated()){
            history.push("/dashboard");
        }
        // eslint-disable-next-line
    }, [])

    const history = useHistory();

    function setCompanyUse(token, name){
        setCompanyAuth(token, name)
        history.push("/dashboard")
    }

    const handleCodeChange = (e)=>{
        let val = e.target.value
        console.log(e.target.value)
        if(val.length<=8){
            setCompanyCode(val)
        }
    }

    async function session(data) {
        if(data.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)){
            setOpen(true);
            if(data.email!=="" && data.password!==""){
                const res = await api.post('/login', {
                    email:data.email,
                    password:data.password,
                })
                console.log(res.data)

                if(res.data.code===0){
                    setAlert({
                        open: true,
                        message: res.data.message,
                        type: 'success'
                    })
                    login(res.data.auth.token, res.data.auth.name,res.data.auth.employee);
                    if(res.data.data.company.length>1){
                        setCompany(res.data.data.company)
                        setCompanyDialog(true)
                    }else{
                        setCompanyAuth(res.data.data.company[0].token, res.data.data.company[0].name)
                        history.push("/dashboard")
                    }

                }else{
                    setAlert({
                        open: true,
                        message: res.data.message,
                        type: 'error'
                    })
                }
            }else{
                setAlert({
                    open: true,
                    message: "Falta preencher campos",
                    type: 'error'
                })
            }
            setOpen(false);
        }else{
            setAlert({
                open: true,
                message: "O campo email não foi preenchido corretamente",
                type: 'error'
            })
        }

    }

    async function loginDevice(){
        api.post('login/device',{
            codeCompany:companyCode
        }).then(res=>{
            if(res.data.code===0){
                setCompanyAuth(res.data.data.token, res.data.data.name)
                history.push("/picking")
            }else{
                setCompanyCodeDialog(false);
                Swal.fire({
                    text:res.data.message,
                    icon:"error",
                    timerProgressBar:true,
                    timer:1500
                }).then(()=>{
                    setCompanyCodeDialog(true)
                })
            }
        })
    }

    return (
        <Container component="main" maxWidth="xs">
            {/*<div className={classes.paper}>*/}
            <div>
               {/* <img src={bk}/>*/}
                <Form onSubmit={data=>session(data)} className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{type: "email"}}
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Iniciar sessão
                    </Button>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        type={"button"}
                        className={classes.submit}
                        onClick={()=>setCompanyCodeDialog(true)}
                    >
                        Dispositivo de picar o ponto
                    </Button>
                </Form>
                <Collapse in={alert.open} >
                    <Alert severity={alert.type}>
                        {alert.message}
                    </Alert>
                </Collapse>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            <Backdrop className={classes.backdrop} open={open} onClick={()=>{}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={companyDialog} sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}>
                <DialogTitle>Empresa</DialogTitle>
                <DialogContent dividers>
                    <List>
                        {company.map((row,idx)=>{
                            return(
                                <ListItem key={idx} button onClick={()=>setCompanyUse(row.token,row.name)}>{row.name}</ListItem>
                            )
                        })}
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog open={companyCodeDialog} onClose={()=>setCompanyCodeDialog(false)} sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}>
                <DialogTitle>Código de empresa</DialogTitle>
                <DialogContent dividers>
                    <TextFieldOriginal
                        autoFocus
                        onChange={e=>handleCodeChange(e)}
                        margin="dense"
                        id="code"
                        label="Código (max: 8)"
                        type="number"
                        fullWidth
                        value={companyCode}
                        variant="standard"
                        onKeyPress={e=>e.key==="Enter"?loginDevice():null}
                    />
                </DialogContent>
            </Dialog>
        </Container>
    );
}
