import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./services/PrivateRoute";
import EmployeeList from "./pages/employee/List";
import EmployeeEdit from "./pages/employee/Edit";
import EmployeeNew from "./pages/employee/New";
import DepartmentList from "./pages/department/List";
import ScheduleList from "./pages/schedule/List";
import ScheduleEdit from "./pages/schedule/Edit";
import ScheduleNew from "./pages/schedule/New";
import PickingRoutes from "./services/PickingRoute";
import Picking from "./pages/picking/Picking";
import ScheduleNewFlexible from "./pages/schedule/NewFlexible";
import InconsistenciesList from "./pages/picking/Inconsistencies";
import JustifyList from "./pages/justify/List";  
import PickingList from "./pages/picking/PickingList";
import ListUser from "./pages/user/List";
import NewUser from "./pages/user/New";
import EditUser from "./pages/user/Edit";
import ReportsDiary from "./pages/reports/Diary";
import ReportsMonth from "./pages/reports/Month";
import Vacation from "./pages/vacation";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/" exact={true} component={Login} />
            <Route path="/login" exact={true} component={Login} />
            <PrivateRoute path="/dashboard" exact={true} component={Dashboard} />
            <PrivateRoute path="/employee" exact={true} component={EmployeeList} />
            <PrivateRoute path="/employee/new" exact={true} component={EmployeeNew} />
            <PrivateRoute path="/employee/:id" exact={true} component={EmployeeEdit} />

            <PrivateRoute path="/department" exact={true} component={DepartmentList} />

            <PrivateRoute path="/schedule" exact={true} component={ScheduleList} />
            <PrivateRoute path="/schedule/new" exact={true} component={ScheduleNew} />
            <PrivateRoute path="/schedule/new/flexible" exact={true} component={ScheduleNewFlexible} />
            <PrivateRoute path="/schedule/:id" exact={true} component={ScheduleEdit} />

            <PrivateRoute path="/picking/inconsistencies" exact={true} component={InconsistenciesList} />
            <PrivateRoute path="/picking/list" exact={true} component={PickingList} />

            <PrivateRoute path={"/user"} exact={true} component={ListUser} />
            <PrivateRoute path={"/user/new"} exact={true} component={NewUser} />
            <PrivateRoute path={"/user/edit/:id"} exact={true} component={EditUser} />

            <PrivateRoute path={"/holidays"} exact={true} component={Vacation} />

            <PrivateRoute path="/justify" exact={true} component={JustifyList} />

            <PrivateRoute path="/reports/diary" exact={true} component={ReportsDiary} />
            <PrivateRoute path="/reports/month" exact={true} component={ReportsMonth} />

            <PickingRoutes path="/picking" exact={true} component={Picking} />

            <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
